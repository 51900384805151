import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter } from 'react-router-dom';
import axios from "./utils/axios";
import MyContext from "./utils/context";
// import "moment/locale/zh-cn";
import { Spin } from "antd";
import Launcher from "./pages/launcher";
import "./styles/App.less";
// // 自己写的加menu
// import { menuData } from "./mock/mockMenu";
const Container = React.lazy(() => import("./layouts/Container"));

const App = () => {
  const [logged, setLogged] = useState(false);
  const [menu, setMenu] = useState([]);
  const [account, setAccount] = useState({});

  async function getMenu() {
    const { data } = await axios.get(
      "system/account/menu/list",
      {
        flag: 1,
      }
    );
    if (data.success) {
      // 新的登录接口
      setMenu(data.data);
      setAccount(sessionStorage.getItem("yewuyuan"));
      // 自己写的加menu
      // setMenu(menuData.data.menu);
      // setAccount(menuData.data.account);
    }
  }


  useEffect(() => {
    if (menu.length) {
      setLogged(true);
    }
  }, [menu, account]);

  useEffect(() => {
    // 旧的登录接口
    // let token = sessionStorage.getItem("token");
    // if (token) {
    //   getMenu();
    // }
    // 新的登录接口
    let token = sessionStorage.getItem("token");
    let sessionId = sessionStorage.getItem("sessionId");
    if (sessionId && token) {
      getMenu();
    }



  }, []);



  return (
    <BrowserRouter basename="/">
      {!logged ? (
        <Launcher />
      ) : (
        <Suspense
          fallback={
            <div className="spin-wrapper">
              <Spin />
            </div>
          }
        >
          <MyContext.Provider value={{ menu, account }}>
            <Container />
          </MyContext.Provider>
        </Suspense>
      )}
    </BrowserRouter>
  );
};

export default App;
